/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import url("https://fonts.googleapis.com/css2?family=Actor&family=Kulim+Park:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap");
@font-face {
  font-family: 'MazdaType';
  src: url('https://pt.cdn.mazda.media/assets/styles/fonts/MazdaType-Medium.woff2') format('woff2'),
       url('https://pt.cdn.mazda.media/assets/styles/fonts/MazdaType-Bold.woff2') format('woff2'),
       url('https://pt.cdn.mazda.media/assets/styles/fonts/MazdaType-Regular.woff2') format('woff2');
}
@import "~@ng-select/ng-select/themes/material.theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

* {
  margin: 0;
  font-family: 'MazdaType';
}

.mdc-notched-outline__notch {
  border: none;
}
